import { generateRandomFileName } from '@/utils/fileTypeUtils.js'
import {
  getStorage,
  ref,
  uploadString,
  connectStorageEmulator,
} from 'firebase/storage'

export const getFirebaseStorage = () => {
  const storage = getStorage()
  // Use emulator if in development mode
  if (process.env.NODE_ENV === 'development') {
    connectStorageEmulator(storage, 'localhost', 9199)
  }
  return storage
}

export const fileUpload = (file_content, file_type) => {
  const fileName = generateRandomFileName(file_type)
  const filePath = `manual/${fileName}`
  return uploadString(
    ref(getFirebaseStorage(), filePath),
    file_content,
    'data_url'
  )
}

export default {
  getFirebaseStorage,
  fileUpload,
}
